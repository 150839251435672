import {BI_ORIGINS} from '@wix/wix-events-commons-statics'
import {ChangeRsvpModalMode, MembersModal} from '@wix/wix-events-commons-statics'
import {getEventId, isNoResponseEnabled} from '@wix/wix-events-commons-statics'
import {Dispatch} from 'redux'
import {callAPI, createActions} from '../../commons/actions/data-action-helper'
import {isPreview} from '../../commons/selectors/environment'
import {openDialogModal, openModal} from '../../commons/services/modal'
import {getEventRsvp} from '../selectors/event'
import {DetailedEvent, GetState, StoreExtraArgs} from '../types/state'

export const DELETE_RSVP = createActions('DELETE_RSVP')
export const CANCEL_RSVP = createActions('CANCEL_RSVP')

export const cancelRsvp = (event: DetailedEvent) => (
  dispatch: Dispatch,
  getState: GetState,
  {wixCodeApi, compId, baseUrl}: StoreExtraArgs,
) => {
  const eventId = getEventId(event.event)
  const state = getState()

  if (isPreview(state)) {
    return openModal({
      type: MembersModal.NO_CANCEL_RSVP,
      wixCodeApi,
      baseUrl,
      compId,
      params: {eventId, origin: BI_ORIGINS.MEMBERS_AREA},
    })
  } else {
    return openDialogModal({
      type: MembersModal.UPDATE_RSVP,
      wixCodeApi,
      baseUrl,
      compId,
      params: {eventId, mode: ChangeRsvpModalMode.MEMBERS_PAGE, origin: BI_ORIGINS.MEMBERS_AREA},
      onConfirm: () => dispatch(onConfirmUpdateRsvp(event) as any),
    })
  }
}

const onConfirmUpdateRsvp = (event: DetailedEvent) => {
  if (isNoResponseEnabled(event.event)) {
    return callAPI(CANCEL_RSVP, {
      eventId: getEventId(event.event),
      rsvpId: getEventRsvp(event).id,
    })
  } else {
    return callAPI(DELETE_RSVP, {
      eventId: getEventId(event.event),
      rsvpId: getEventRsvp(event).id,
    })
  }
}
