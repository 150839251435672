import {applyMiddleware, createStore, DeepPartial, Middleware, Reducer} from 'redux'
import thunk from 'redux-thunk'
import {MemberPageState, StoreExtraArgs} from '../types/state'

interface StoreConfig {
  reducers: Reducer
  initialData: DeepPartial<MemberPageState>
  extraArguments: StoreExtraArgs
  middleware: Middleware[]
}

export const createReduxStore = ({reducers, initialData, extraArguments, middleware}: StoreConfig) => {
  return createStore(reducers, initialData, applyMiddleware(thunk.withExtraArgument(extraArguments), ...middleware))
}
