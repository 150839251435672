import {IWidgetControllerConfig} from 'native-components-infra/dist/src/types/types'
import {createMembersPageController as createMembersAppController} from './members-page/controllers/members-page'
import {createWidgetController} from './widget/controllers'
import {initSentry} from './widget/services/error'

export const EVENTS_WIDGET_ID = '1440e92d-47d8-69be-ade7-e6de40127106'
export const EVENTS_MEMBERS_WIDGET_ID = '405eb115-a694-4e2b-abaa-e4762808bb93'

export const createControllers = (controllerConfigs: IWidgetControllerConfig[]) =>
  controllerConfigs.map(c => {
    if (c.type === EVENTS_WIDGET_ID) {
      initSentry(c)
      return createWidgetController(c)
    } else if (c.type === EVENTS_MEMBERS_WIDGET_ID) {
      initSentry(c)
      return createMembersAppController(c)
    }
  })
