import {IWixAPI} from 'native-components-infra/dist/src/types/types'
import {applyMiddleware, createStore, DeepPartial, Middleware, Reducer} from 'redux'
import thunk from 'redux-thunk'
import {State} from '../types/state'
import {Api} from './api'

interface StoreConfig {
  reducers: Reducer
  initialData: DeepPartial<State>
  middleware: Middleware[]
  serverApi: Api
  wixCodeApi: IWixAPI
  compId: string
}

export const createReduxStore = ({reducers, initialData, middleware, serverApi, wixCodeApi, compId}: StoreConfig) => {
  return createStore(
    reducers,
    initialData,
    applyMiddleware(thunk.withExtraArgument({serverApi, wixCodeApi, compId}), ...middleware),
  )
}
