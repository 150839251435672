import {
  BUTTONS_STYLE,
  ComponentConfig,
  HORIZONTAL_IMAGE_POSITION,
  IMAGE_SCALING,
  LIST_IMAGE_LAYOUT,
  LIST_LAYOUT,
  LOCATION_AND_DATE_FORMAT,
  VERTICAL_IMAGE_POSITION,
  WIDGET_LAYOUT,
  WIDGET_TYPE,
} from '../constants/settings'

export const isResponsive = (componentConfig: ComponentConfig) => componentConfig.settings.responsive

export const getWidgetType = (componentConfig: ComponentConfig) => componentConfig.settings.widgetType

export const getWidgetLayout = (componentConfig: ComponentConfig) => componentConfig.settings.widgetLayout

export const getImageWidth = (componentConfig: ComponentConfig) => componentConfig.settings.imageWidth

export const getImageGridPosition = (componentConfig: ComponentConfig) => componentConfig.settings.imageGridPosition

export const getImageOpacity = (componentConfig: ComponentConfig) => componentConfig.settings.imageOpacity

export const getImageRatio = (componentConfig: ComponentConfig) => componentConfig.settings.imageRatio

export const getImagePosition = (componentConfig: ComponentConfig) => componentConfig.settings.imagePosition

export const getVerticalImagePosition = (componentConfig: ComponentConfig) =>
  componentConfig.settings.verticalImagePosition

export const getCountdownMarginBottom = (componentConfig: ComponentConfig) =>
  componentConfig.settings.countdownMarginBottom

export const getDateAndLocationMarginBottom = (componentConfig: ComponentConfig) =>
  componentConfig.settings.dateAndLocationMarginBottom

export const getDescriptionMarginBottom = (componentConfig: ComponentConfig) =>
  componentConfig.settings.descriptionMarginBottom

export const getTitleMarginBottom = (componentConfig: ComponentConfig) => componentConfig.settings.titleMarginBottom

export const getDateMarginBottom = (componentConfig: ComponentConfig) => componentConfig.settings.dateMarginBottom

export const getRsvpMarginBottom = (componentConfig: ComponentConfig) => componentConfig.settings.rsvpMarginBottom

export const getLocationMarginBottom = (componentConfig: ComponentConfig) =>
  componentConfig.settings.locationMarginBottom

export const getAlignment = (componentConfig: ComponentConfig) => componentConfig.settings.alignment

export const getContentAlignment = (componentConfig: ComponentConfig) => componentConfig.settings.contentAlignment

export const getOneButtonWidth = (componentConfig: ComponentConfig) => componentConfig.settings.oneButtonWidth

export const getButtonHeight = (componentConfig: ComponentConfig) => componentConfig.settings.buttonHeight

export const getButtonBorderRadius = (componentConfig: ComponentConfig) => componentConfig.settings.buttonsBorderRadius

export const getRoundedButtonBorderRadius = (componentConfig: ComponentConfig) =>
  componentConfig.settings.roundedButtonsBorderRadius

export const getListImageLayout = (componentConfig: ComponentConfig) => componentConfig.settings.listImageLayout

export const getListImagePosition = (componentConfig: ComponentConfig) => componentConfig.settings.listImagePosition

export const getListImageOpacity = (componentConfig: ComponentConfig) => componentConfig.settings.listImageOpacity

export const getListAlignment = (componentConfig: ComponentConfig) => componentConfig.settings.listAlignment

export const getListImageWidth = (componentConfig: ComponentConfig) => componentConfig.settings.listImageWidth

export const getListDividerWidth = (componentConfig: ComponentConfig) => componentConfig.settings.listDividerWidth

export const getListStripWidth = (componentConfig: ComponentConfig) => componentConfig.settings.listStripWidth

export const getListLocationAndDateFormat = (componentConfig: ComponentConfig) =>
  componentConfig.settings.listLocationAndDateFormat

export const getListButtonStyle = (componentConfig: ComponentConfig) => componentConfig.settings.listButtonStyle

export const getCardsPerRow = (componentConfig: ComponentConfig) => componentConfig.settings.cardsPerRow

export const getCardMargins = (componentConfig: ComponentConfig) => componentConfig.settings.cardMargins

export const getCardBorderWidth = (componentConfig: ComponentConfig) => componentConfig.settings.cardBorderWidth

export const getSideBySideLayoutMargins = (componentConfig: ComponentConfig) =>
  componentConfig.settings.sideBySideLayoutMargins

export const getCalendarWeekStartDay = (componentConfig: ComponentConfig) =>
  componentConfig.settings.calendarWeekStartDay

export const getCalendarCellBorderWidth = (componentConfig: ComponentConfig) =>
  componentConfig.settings.calendarCellBorderWidth

export const getTodayButtonStyle = (componentConfig: ComponentConfig) => componentConfig.settings.todayButtonStyle

export const getVerticalDividerWidth = (componentConfig: ComponentConfig) =>
  componentConfig.settings.verticalDividerWidth

export const getVerticalDividerHeight = (componentConfig: ComponentConfig) =>
  componentConfig.settings.verticalDividerHeight

export const getHorizontalDividerWidth = (componentConfig: ComponentConfig) =>
  componentConfig.settings.horizontalDividerWidth

export const getHorizontalDividerHeight = (componentConfig: ComponentConfig) =>
  componentConfig.settings.horizontalDividerHeight

export const getCompactHorizontalDividerWidth = (componentConfig: ComponentConfig) =>
  componentConfig.settings.compactHorizontalDividerWidth

export const getCompactHorizontalDividerHeight = (componentConfig: ComponentConfig) =>
  componentConfig.settings.compactHorizontalDividerHeight

export const getListLayout = (componentConfig: ComponentConfig) => componentConfig.settings.listLayout

export const isMultiEventWidget = (componentConfig: ComponentConfig) =>
  getWidgetType(componentConfig) === WIDGET_TYPE.LIST

export const isSingleEventWidget = (componentConfig: ComponentConfig) =>
  getWidgetType(componentConfig) === WIDGET_TYPE.SINGLE

export const isHorizontalLayout = (componentConfig: ComponentConfig) =>
  getWidgetLayout(componentConfig) === WIDGET_LAYOUT.FULL

export const isVerticalLayout = (componentConfig: ComponentConfig) =>
  getWidgetLayout(componentConfig) === WIDGET_LAYOUT.VERTICAL

export const isButtonLayout = (componentConfig: ComponentConfig) =>
  getWidgetLayout(componentConfig) === WIDGET_LAYOUT.BUTTON_ONLY

export const isBackgroundLayout = (componentConfig: ComponentConfig) =>
  getWidgetLayout(componentConfig) === WIDGET_LAYOUT.BACKGROUND

export const isRightLayout = (componentConfig: ComponentConfig) =>
  isHorizontalLayout(componentConfig) && componentConfig.settings.imagePosition === HORIZONTAL_IMAGE_POSITION.RIGHT

export const isBottomLayout = (componentConfig: ComponentConfig) =>
  isVerticalLayout(componentConfig) && componentConfig.settings.verticalImagePosition === VERTICAL_IMAGE_POSITION.BOTTOM

export const isSideBySideLayout = (componentConfig: ComponentConfig) =>
  getListLayout(componentConfig) === LIST_LAYOUT.SIDE_BY_SIDE

export const isListLayout = (componentConfig: ComponentConfig) => getListLayout(componentConfig) === LIST_LAYOUT.LIST

export const isCardsLayout = (componentConfig: ComponentConfig) =>
  getListLayout(componentConfig) === LIST_LAYOUT.NEW_GRID

export const isCalendarLayout = (componentConfig: ComponentConfig) =>
  getListLayout(componentConfig) === LIST_LAYOUT.CALENDAR

export const isLabelEnabled = (componentConfig: ComponentConfig) => componentConfig.settings.showLabels

export const isImageEnabled = (componentConfig: ComponentConfig) => componentConfig.settings.showImage

export const isCountdownEnabled = (componentConfig: ComponentConfig) => componentConfig.settings.showCountdown

export const isTitleEnabled = (componentConfig: ComponentConfig) => componentConfig.settings.showTitle

export const isDescriptionEnabled = (componentConfig: ComponentConfig) => componentConfig.settings.showDescription

export const isDateEnabled = (componentConfig: ComponentConfig) => componentConfig.settings.showDate

export const isLocationEnabled = (componentConfig: ComponentConfig) => componentConfig.settings.showLocation

export const isShareOptionsEnabled = (componentConfig: ComponentConfig) => componentConfig.settings.showShareOptions

export const isRibbonEnabled = (componentConfig: ComponentConfig) => componentConfig.settings.showRibbon

export const isMembersEnabled = (componentConfig: ComponentConfig) => componentConfig.settings.showMembers

export const isCompactDividersEnabled = (componentConfig: ComponentConfig) =>
  componentConfig.settings.showCompactDividers

export const isFullDividersEnabled = (componentConfig: ComponentConfig) => componentConfig.settings.showFullDividers

export const isHorizontalDividersEnabled = (componentConfig: ComponentConfig) =>
  componentConfig.settings.showHorizontalDividers

export const isRsvpTitleEnabled = (componentConfig: ComponentConfig) => componentConfig.settings.showRSVPTitle

export const isListMainTitleEnabled = (componentConfig: ComponentConfig) => componentConfig.settings.listShowMainTitle

export const isListDateEnabled = (componentConfig: ComponentConfig) => componentConfig.settings.listShowDate

export const isListVenueNameEnabled = (componentConfig: ComponentConfig) => componentConfig.settings.listShowVenueName

export const isListFullDateEnabled = (componentConfig: ComponentConfig) => componentConfig.settings.listShowFullDate

export const isListLocationEnabled = (componentConfig: ComponentConfig) => componentConfig.settings.listShowLocation

export const isListDescriptionEnabled = (componentConfig: ComponentConfig) =>
  componentConfig.settings.listShowDescription

export const isListSocialShareEnabled = (componentConfig: ComponentConfig) =>
  componentConfig.settings.listShowSocialShare

export const isListImageEnabled = (componentConfig: ComponentConfig) => componentConfig.settings.listShowImage

export const isListMembersElementEnabled = (componentConfig: ComponentConfig) =>
  componentConfig.settings.listShowMembers

export const isListAdditionalInfoEnabled = (componentConfig: ComponentConfig) =>
  componentConfig.settings.listShowAdditionalInfo

export const isListImageLayoutRight = (componentConfig: ComponentConfig) =>
  componentConfig.settings.listImageLayout === LIST_IMAGE_LAYOUT.RIGHT

export const isCompactDateAndLocation = (componentConfig: ComponentConfig) =>
  componentConfig.settings.locationAndDateFormat === LOCATION_AND_DATE_FORMAT.COMPACT

export const isHorizontalDateAndLocation = (componentConfig: ComponentConfig) =>
  componentConfig.settings.locationAndDateFormat === LOCATION_AND_DATE_FORMAT.HORIZONTAL

export const isVerticalDateAndLocation = (componentConfig: ComponentConfig) =>
  componentConfig.settings.locationAndDateFormat === LOCATION_AND_DATE_FORMAT.VERTICAL

export const isHollowButton = (componentConfig: ComponentConfig) =>
  [BUTTONS_STYLE.HOLLOW, BUTTONS_STYLE.HOLLOW_ROUNDED].includes(componentConfig.settings.buttonsStyle)

export const isRoundedButton = (componentConfig: ComponentConfig) =>
  [BUTTONS_STYLE.FULL_ROUNDED, BUTTONS_STYLE.HOLLOW_ROUNDED].includes(componentConfig.settings.buttonsStyle)

export const isListHollowButton = (componentConfig: ComponentConfig) =>
  [BUTTONS_STYLE.HOLLOW, BUTTONS_STYLE.HOLLOW_ROUNDED].includes(componentConfig.settings.listButtonStyle)

export const isListRoundedButton = (componentConfig: ComponentConfig) =>
  [BUTTONS_STYLE.FULL_ROUNDED, BUTTONS_STYLE.HOLLOW_ROUNDED].includes(componentConfig.settings.listButtonStyle)

export const isTodayButtonHollow = (componentConfig: ComponentConfig) =>
  [BUTTONS_STYLE.HOLLOW, BUTTONS_STYLE.HOLLOW_ROUNDED].includes(componentConfig.settings.todayButtonStyle)

export const isTodayButtonRounded = (componentConfig: ComponentConfig) =>
  [BUTTONS_STYLE.FULL_ROUNDED, BUTTONS_STYLE.HOLLOW_ROUNDED].includes(componentConfig.settings.todayButtonStyle)

export const isFitImage = (componentConfig: ComponentConfig) =>
  componentConfig.settings.imageScaling === IMAGE_SCALING.FIT

export const getUserText = (componentConfig: ComponentConfig, name: string) => componentConfig.settings.texts[name]

export const getListGeneralTitle = (componentConfig: ComponentConfig) =>
  getUserText(componentConfig, 'listGeneralTitle')

export const getOneButtonText = (componentConfig: ComponentConfig) => getUserText(componentConfig, 'oneButton')

export const getRSVPClosedText = (componentConfig: ComponentConfig) =>
  getUserText(componentConfig, 'registrationClosedButtonText')

export const getListButtonText = (componentConfig: ComponentConfig) => getUserText(componentConfig, 'listButtonText')

export const getTodayButtonText = (componentConfig: ComponentConfig) => getUserText(componentConfig, 'todayButtonText')

export const getLocationText = (componentConfig: ComponentConfig) => getUserText(componentConfig, 'location')

export const getTimeText = (componentConfig: ComponentConfig) => getUserText(componentConfig, 'time')

export const getRsvpTitleText = (componentConfig: ComponentConfig) => getUserText(componentConfig, 'RSVPTitle')
