import {IWixAPI} from 'native-components-infra/dist/src/types/types'
import {Dispatch} from 'redux'
import {GetCommonState} from '../types/state'
import {getMultilingualFullLocale} from '../utils/wix-code-api'

export const SET_BASE_ENVIRONMENT = 'SET_BASE_ENVIRONMENT'

export const setBaseEnvironment = () => (
  dispatch: Dispatch,
  getState: GetCommonState,
  {wixCodeApi}: {wixCodeApi: IWixAPI},
) => {
  const state = getState()
  const locale = getMultilingualFullLocale(wixCodeApi)

  dispatch({
    type: SET_BASE_ENVIRONMENT,
    payload: {
      locale: locale || wixCodeApi.site.regionalSettings || state.siteSettings.locale,
      language: state.siteSettings.language || wixCodeApi.site.language || 'en',
      timezone: wixCodeApi.site.timezone,
      mobile: wixCodeApi.window.formFactor === 'Mobile',
      editor: wixCodeApi.window.viewMode === 'Editor',
      preview: wixCodeApi.window.viewMode === 'Preview',
      site: wixCodeApi.window.viewMode === 'Site',
    },
  })
}
