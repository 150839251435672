import {isRsvp} from '@wix/wix-events-commons-statics'
import {VisitorType} from '@wix/wix-events-commons-statics/dist/types/enums/events'
import {IWixAPI} from 'native-components-infra/dist/src/types/types'
import {isDetailsPageEnabled} from '../../commons/selectors/site-settings'
import {navigateToDetailsPage} from '../../commons/services/navigation'
import {isRestrictedTo} from '../reducers/members-enabled'
import {withErrorBoundary} from '../services/error'
import {GetState, State} from '../types/state'
import {ensureLoggedIn} from './members'

export const NAVIGATE_TO_PAGE = 'NAVIGATE_TO_PAGE'
export const RSVP_BUTTON_CLICKED = 'RSVP_BUTTON_CLICKED'

export const navigateToPage = (event: wix.events.Event, state: State) =>
  withErrorBoundary(async (dispatch, getState: GetState, {wixCodeApi}: {wixCodeApi: IWixAPI}) => {
    dispatch(rsvpButtonClicked(event))
    const detailsPageEnabled = isDetailsPageEnabled(state.siteSettings)
    const isRestricted = !detailsPageEnabled && isRsvp(event) && isRestrictedTo(event, state, VisitorType.MEMBER)
    if (!isRestricted || (await dispatch(ensureLoggedIn()))) {
      await navigateToDetailsPage(event, detailsPageEnabled, wixCodeApi)
      dispatch({type: NAVIGATE_TO_PAGE, payload: {event}})
    }
  })

const rsvpButtonClicked = (event: wix.events.Event) => ({
  type: RSVP_BUTTON_CLICKED,
  payload: {event},
})
